<template>
  <van-cell title="图像接口" is-link @click="onChooseImage" />
  <van-cell title="自定义转发" is-link @click="onShareAppMessage" />
  <van-cell title="通讯录" is-link @click="onContact" />
  <van-cell title="预览图片" is-link @click="onPreviewImage" />
  <van-cell title="群发" is-link @click="onShareToExternalContact" />
  <van-cell title="通讯录选单人" is-link @click="onSelectEnterpriseContact" />
  <van-cell title="通讯录选单人-打开会话" is-link @click="onSelectAndOpen" />
  <van-cell title="外部联系人选人" is-link @click="onSelectExternalContact" />
  <van-cell
    title="外部联系人选单人-打开会话"
    is-link
    @click="onSelectExternalAndOpen"
  />
  <van-cell
    title="打开群看板（静态页面-完成后将无法查看）"
    is-link
    @click="$router.push('/community/analyse')"
  />
  <van-button type="primary" block @click="onCopyToken">复制token</van-button>
</template>

<script>
import { Cell, CellGroup, Field, Toast, Button } from 'vant'
import { wechatConfig, wechatAgentConfig } from '@/utils/wechat.js'
import {
  shareToExternalContact,
  selectEnterpriseContact,
  openEnterpriseChat,
  selectExternalContact
} from '@/utils/nwechat.js'

import { reactive, computed, onMounted } from 'vue'
import { useStore } from 'vuex'

export default {
  name: '404',
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Field.name]: Field,
    [Toast.name]: Toast,
    [Button.name]: Button
  },
  setup(props) {
    const store = useStore()
    const state = reactive({
      wx: null
    })
    //自定义转发到会话
    const onShareAppMessage = () => {
      state.wx.invoke('shareAppMessage', null, function (res) {
        if (res.err_msg == 'shareAppMessage:ok') {
        }
      })
    }
    //选择图片
    const onChooseImage = () => {
      state.wx.chooseImage({
        count: 1, // 默认9
        sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
        defaultCameraMode: 'batch', //表示进入拍照界面的默认模式，目前有normal与batch两种选择，normal表示普通单拍模式，batch表示连拍模式，不传该参数则为normal模式。从3.0.26版本开始支持front和batch_front两种值，其中front表示默认为前置摄像头单拍模式，batch_front表示默认为前置摄像头连拍模式。（注：用户进入拍照界面仍然可自由切换两种模式）
        isSaveToAlbum: 1, //整型值，0表示拍照时不保存到系统相册，1表示自动保存，默认值是1
        success: function (res) {
          var localIds = res.localIds // 返回选定照片的本地ID列表，
          // andriod中localId可以作为img标签的src属性显示图片；
          // iOS应当使用 getLocalImgData 获取图片base64数据，从而用于img标签的显示（在img标签内使用 state.wx.chooseImage 的 localid 显示可能会不成功）
        }
      })
    }
    //选择通讯录
    const onContact = () => {
      state.wx.invoke(
        'selectEnterpriseContact',
        {
          fromDepartmentId: -1, // 必填，表示打开的通讯录从指定的部门开始展示，-1表示自己所在部门开始, 0表示从最上层开始
          mode: 'multi', // 必填，选择模式，single表示单选，multi表示多选
          type: ['department', 'user'], // 必填，选择限制类型，指定department、user中的一个或者多个
          selectedDepartmentIds: ['2', '3'], // 非必填，已选部门ID列表。用于多次选人时可重入，single模式下请勿填入多个id
          selectedUserIds: ['lisi', 'lisi2'] // 非必填，已选用户ID列表。用于多次选人时可重入，single模式下请勿填入多个id
        },
        function (res) {
          if (res.err_msg == 'selectEnterpriseContact:ok') {
            if (typeof res.result == 'string') {
              res.result = JSON.parse(res.result) //由于目前各个终端尚未完全兼容，需要开发者额外判断result类型以保证在各个终端的兼容性
            }
            var selectedDepartmentList = res.result.departmentList // 已选的部门列表
            for (var i = 0; i < selectedDepartmentList.length; i++) {
              var department = selectedDepartmentList[i]
              var departmentId = department.id // 已选的单个部门ID
              var departemntName = department.name // 已选的单个部门名称
            }
            var selectedUserList = res.result.userList // 已选的成员列表
            for (var i = 0; i < selectedUserList.length; i++) {
              var user = selectedUserList[i]
              var userId = user.id // 已选的单个成员ID
              var userName = user.name // 已选的单个成员名称
              var userAvatar = user.avatar // 已选的单个成员头像
            }
          }
        }
      )
    }
    //预览图片
    const onPreviewImage = () => {
      state.wx.previewImage({
        current:
          'https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=1039933548,2071774828&fm=26&gp=0.jpg', // 当前显示图片的http链接
        urls: [
          'https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=1039933548,2071774828&fm=26&gp=0.jpg'
        ] // 需要预览的图片http链接列表
      })
    }
    //复制token
    const onCopyToken = () => {
      state.wx.setClipboardData({
        data: store.getters.token,
        success: function (res) {
          console.log(res.errMsg)
          Toast('token复制成功！')
        }
      })
    }

    // 群发
    const onShareToExternalContact = async () => {
      try {
        await shareToExternalContact({
          title: undefined, // 消息的标题
          desc: undefined, // 消息的描述
          link: undefined, // 消息链接
          imgUrl: undefined // 消息封面
        })
      } catch (e) {
        console.log(e)
      }
    }

    // 通讯录选单人
    const onSelectEnterpriseContact = async () => {
      try {
        const res = await selectEnterpriseContact({
          fromDepartmentId: -1,
          mode: 'single',
          type: ['user']
        })
        console.log('A:', res)
      } catch (e) {
        console.log('B:', e)
      }
    }

    // 通讯录选单人-打开会话
    const onSelectAndOpen = async () => {
      try {
        const res = await selectEnterpriseContact({
          fromDepartmentId: -1,
          mode: 'single',
          type: ['user']
        })
        const userid = res.result.userList[0].id
        await openEnterpriseChat({ userIds: userid })
        console.log('AA:', res)
      } catch (e) {
        console.log('BB:', e)
      }
    }

    // 外部联系人选人
    const onSelectExternalContact = async () => {
      try {
        const res = await selectExternalContact()
        console.log(res)
      } catch (e) {
        console.log(e)
      }
    }

    // 外部联系人选人-打开会话
    const onSelectExternalAndOpen = async () => {
      try {
        const res = await selectExternalContact()
        await openEnterpriseChat({ externalUserIds: res.userIds.join(';') })
      } catch (e) {}
    }

    //同一个url仅需config一次
    onMounted(() => {
      wechatConfig((res) => {
        state.wx = res
      })
    })
    return {
      onContact,
      onChooseImage,
      onShareAppMessage,
      onPreviewImage,
      onCopyToken,
      onShareToExternalContact,
      onSelectEnterpriseContact,
      onSelectAndOpen,
      onSelectExternalContact,
      onSelectExternalAndOpen
    }
  }
}
</script>
